import React from 'react';
import ScheduleSelector from 'react-schedule-selector'
import { Container, Card, Alert, Row, Col, Button, Navbar, Nav } from 'react-bootstrap';
//import calData from './data.json'
import queryString from 'query-string';
import { withRouter } from "react-router";

// import components
import Navigation from './Navigation'

class Scheduler extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        schedule: [],
        blocked: [],
        email: ""
    }
    this.save = this.save.bind(this);
  }

  

  updateState = () => {

    // get data from Cloud Function
    fetch('https://us-central1-gpj-availability.cloudfunctions.net/gpjAvailability/list')
    .then(res => res.json())
    .then((data) => {
      const block_data = [];
      for (const item in data) {
        const i = data[item]["event"];
        if (i["dateTime"]) {
            block_data.push(String(new Date(i["dateTime"])))
        }
      }
      // now set the state
      console.log("mount")
      this.setState({blocked: block_data}, () => {
        console.log("blocked updated");
        console.log(this.state)
      });
        
    })
    

    /*
    // get local data to reduce cost
    const block_data = [];
    for (const item in calData) {
      const i = calData[item]["event"];
      if (i["dateTime"]) {
          block_data.push(String(new Date(i["dateTime"])))
      }
    }

    // now set the state
    console.log("mount")
    this.setState({blocked: block_data}, () => {
      console.log("blocked updated");
      console.log(this.state)
    });
    */
    
  };

  

  // 
  componentDidMount() {
    this.updateState()
    let params = queryString.parse(this.props.location.search)
    if (params["e"]) {
        // parse email
        //const buf = Buffer.from('gareth@garethpaul.com', 'utf8');
        //const encodedString = buf.toString('base64');
        var b = Buffer.from(params["e"], 'base64').toString('utf-8');
        this.setState({email: b})
    }
  }

  // Save the Data From Display

submitForm (e) {
    e.preventDefault()
    this.save();

}

async save(){  
    console.log("save")
    var list = this.state.schedule;
    for (const c in list){
        /* SAMPLE HTTP REQUEST
        {
            "eventName": "Firebase Event",
            "description": "This is a sample description",
            "startTime": "2018-12-01T10:00:00",
            "endTime": "2018-12-01T13:00:00"
        }
        */
       const endTime = new Date(this.state.schedule[c]).setHours(new Date(this.state.schedule[c]).getHours()+1)
        const requestOptions = {
            method: 'POST',
            header: {
                'content-type' : 'application/json'
            },
            body: JSON.stringify({ eventName: "Proposed 1:1 w/" + this.state.email, 
                                   description: "This is a proposal for a 1:1",
                                   startTime: this.state.schedule[c],
                                   endTime: new Date(endTime).toISOString()})
        };
        console.log(requestOptions)
        await fetch('https://us-central1-gpj-availability.cloudfunctions.net/gpjAvailability/post', requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("sent request");
            });
            //.then(data => this.setState({ postId: data.id }));
    }
    this.props.history.push('/thankyou');
    
  }
    

  
  handleChange = newSchedule => {
    this.setState({ schedule: newSchedule })
  }



  render() {
    // Check If Should Redirect
    // check that we have the state for the current calendar
    if (this.state.blocked.length > 0) {
        // if the email is not empty render the page
        if (this.state.email !== "") {
            return (
                <>
                    <Navigation />
                    <Container style={{margin: "auto", marginTop: "90px", width: "900px"}}>
            
                    <Row>
                        <h2>Select Availability</h2>
                    </Row>
                    <Row>

                    </Row>
                    <Row style={{ marginBottom: "30px"}}>
                    <p className="mb-0">
                    <span id="helpBlock" class="help-block">
                        All times shown in your local time zone (GMT-07:00) (PDT)
                    </span>
                    </p>
                    </Row>
 

                    <Card style={{ width: '90%' }}>
                        <Card.Body>
                        <ScheduleSelector
                                selection={this.state.schedule}
                                blocked={this.state.blocked}
                                numDays={7}
                                minTime={9}
                                maxTime={16}
                                onChange={this.handleChange}
                                />
                            <Container style={{ margin: "20px"}}>
                            <Row>
                                <div className="clearfix" style={{ width: "100%", padding: '20px'}}>
                                <form onSubmit={this.submitForm.bind(this)}>
                                <Button type="submit" variant="primary" style={{ padding: '10px', "float": "right"}} className="btn btn-primary float-right">Submit Availability</Button>
                				</form>
                                </div>
                            </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                    </Container> 
                    <Navbar bg="dark" variant="dark" style={{ marginTop: "100px"}}>
                    <Navbar.Brand href="#home">
                    </Navbar.Brand>
                    </Navbar>
                </>        
            ) // end render
        } else {
            // tell the user that you are waiting on an email
            return(<p>Scheduling Requires your email</p>)
        }
    } else {
      return (
        <p>Waiting for Data</p>
      )

    }
    
  }
}

export default withRouter(Scheduler);