import React from 'react';
import ScheduleSelector from 'react-schedule-selector'
import { Container, Card, Alert, Row, Col, Button, Navbar, Nav } from 'react-bootstrap';
//import calData from './data.json'
import queryString from 'query-string';
import { withRouter } from "react-router";

// import components
import Navigation from './Navigation'

class ThankYou extends React.Component {
  
  render() {
      return(
                <>
                    <Navigation />
                    <Container style={{margin: "auto", marginTop: "90px", width: "900px"}}>
            
                    <Row>
                        <Alert variant="success" style={{ width: "90%"}}>
                        <Alert.Heading>👍</Alert.Heading>
                        <p>
                            Thanks for submitting your availability.
                        </p>
                        </Alert>
                    </Row>
                    </Container>
                </>
      )
    }
}

export default withRouter(ThankYou);