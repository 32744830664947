import React from 'react';
import './App.css';
import Scheduler from './components/Scheduler'
import ThankYou from './components/ThankYou'
import { Switch, Route, BrowserRoute } from 'react-router-dom';


class App extends React.Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {  
  }


  render() {
    return (
      <main>
          <Switch>
            <Route path='/' exact component={Scheduler} />
            <Route path='/thankyou' exact component={ThankYou} />
          </Switch>
      </main> 
    )
 
  }
}

export default App;
