import React from 'react';
import { Navbar } from 'react-bootstrap';

class Navigation extends React.Component {
    render() {
        return (
            <>
            <Navbar bg="light" variant="light fixed-top">
                <Navbar.Brand href="#home">
                <img
                    alt=""
                    src="/logo.svg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />{' '}
                garethpaul
                </Navbar.Brand>
            </Navbar>
        </>
        )
    }
}

export default Navigation;
